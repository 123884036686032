import * as React from "react"

import { Stack } from "/src/components/grid"
import { ButtonLink } from "/src/components/button"
import * as Text from "/src/components/text"
import Header from "/src/components/header"

const Container = ({ children }) => {

    return (
        <div style={{
            maxWidth: 480,
            margin: `0 auto`,
            padding: `0 1.0875rem`,
        }}>
            {children}
        </div>
    )
}


const SuccessImg = () => (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: "0 auto", display: "block"}}>
        <circle cx="48" cy="48" r="48" fill="#339136" />
        <path d="M44.4 70L24 45.4783L44.4 53.6522L75 23L44.4 70Z" fill="white" />
    </svg>

)

const Results = () => {

    return (
        <div>
            <Header />
            <div style={{ paddingTop: 144 }}></div>

            <Container>
                <Stack gap={24} style={{marginTop: 120}} align="center">
                    <SuccessImg />
                    <Text.H1 center>Дякуємо</Text.H1>
                    <Text.Center>
                        Ми зв‘яжемось з вами для підписання договору
                    </Text.Center>
                </Stack>
            </Container>
        </div>
    )
}

export default Results